/*
 * @Author: zhangyuansheng zhangyuansheng@elinkmart.com
 * @Date: 2025-02-20 15:13:00
 * @LastEditors: zhangyuansheng zhangyuansheng@elinkmart.com
 * @LastEditTime: 2025-02-21 15:29:58
 * @FilePath: \产品网站3.0\src\router\modules\ProductIntroduction.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const ProductIntroductionRoute = [
  {
    path: "/nowmessage",
    name: "nowmessage",
    component: () => import("../../views/productintroduce/message/index.vue"),
    meta: {
      title: "传信及时通讯",
      keywords: '移动展业,移动营销,厅堂展业,移动开发平台,开发组件,开发环境,跨平台开发框架,虚拟化技术,自动化构建工具,快速迭代,部署,提高开发效率',
      description: '领雁科技-即时通讯,移动办公一体化软件,领雁科技-移动开发平台'
    },
  },
  {
    path: "/work",
    name: "work",
    component: () => import("@/views/productintroduce/Work.vue"),
    meta: {
      title: "传信移动办公",
      icon: "list",
      noCache: true,
      keywords: '移动办公,便捷办公',
      description: '考勤打卡,统一待办,流程审批'
    },
  },
  {
    path: "/development",
    name: "development",
    component: () => import("@/views/productintroduce/DevelopmentPlatform.vue"),
    meta: {
      title: "传信开发平台",
      icon: "list",
      noCache: true,
      keywords: '敏捷的开发平台,开发(前端组件、绘制页面、联调接口),构建(生成代码、构建打包、自动部署),运行（安全通信、应用管理、审核发布）,运营(埋点日志、动态修复、行为分析)',
      description: '敏捷的开发平台:我们的开发平台为开发人员提供的一套工具、环境和资源，用于创建、 测试和部署软件应用程序或系统。并且提供了一个集成的开发环境，以简化开发过程、 提高开发效率，并支持应用程序的快速迭代和部署。'
    },
  },
  {
    path: "/centre",
    name: "centre",
    component: () => import("@/views/productintroduce/CentrePlatform.vue"),
    meta: {
      title: "传信移动中台",
      icon: "list",
      noCache: true,
      keywords: '支持多APP/渠道快速接入,审核/正式灰度发布,通信数据文件都加密/安全防重放',
      description: '实现业务的整合、数据的共享和流程的优化:将企业的各个业务和功能模块通过移动技术进行整合和连接形成一个统一的移动化平台。'
    },
  },
  {
    path: "/operation",
    name: "operation",
    component: () => import("@/views/productintroduce/OperationPlatform.vue"),
    meta: {
      title: "传信运营平台",
      icon: "list",
      noCache: true,
      keywords: '强大的全日志分析,精准的API分析,易用的行为分析',
      description: '高效的运营平台:提供了一系列功能和工具，帮助企业实现运营目标、提高效率和优化业务流程'
    },
  },
  {
    path: "/moblebank",
    name: "moblebank",
    component: () => import("../../views/productintroduce/MobleBank/index.vue"),
    meta: {
      title: "传信手机银行",
      keywords: '手机银行,转账汇款,账户查询,银行卡管理',
      description: '灵犀一点 智在掌握:用户可以通过手机银行进行各种银行业务操作，无需前往实体银行网点，节省了时间和精力。同时，手机银行也加强了安全措施，如使用指纹识别、动态验证码等，保障用户的资金安全。'
    },
  },
  {
    path: "/moblebusiness",
    name: "moblebusiness",
    component: () => import("../../views/productintroduce/MobleBusiness/index.vue"),
    meta: {
      title: "传信移动展业",
      keywords: '渠道融合服务更新,搭建平台功能齐全,强化管理运维管控',
      description: '移动展业实现无纸化、智能化:专注银行连接客户的“最后一公里打造无界精准的获客，贴心智能的服务'
    },
  },
  {
    path: "/personbank",
    name: "personbank",
    component: () => import("../../views/productintroduce/PersonBank/index.vue"),
    meta: {
      title: "个人网银",
      keywords: '个人网银,服务方便、快捷、高效、可靠,精准数据智能管理',
      description: '多元化金融服务：转账汇款、资金归集、投资理财、手机号支付、储蓄理财.个人贷款、代理缴费等金融服务的网上银行服务'
    },
  },
  {
    path: "/companybank",
    name: "companybank",
    component: () => import("../../views/productintroduce/CompanyBank/index.vue"),
    meta: {
      title: "企业网银",
      keywords: '优化业务流程\打通线上和线下业务渠道,节省成本\提高效率,方便高效的交流互动',
      description: '多元化金融服务：转账汇款、资金归集、投资理财、手机号支付、储蓄理财.个人贷款、代理缴费等金融服务的网上银行服务'
    },
  },
  
];
