/*
 * @Author: zhangyuansheng zhangyuansheng@elinkmart.com
 * @Date: 2025-02-20 15:13:00
 * @LastEditors: zhangyuansheng zhangyuansheng@elinkmart.com
 * @LastEditTime: 2025-02-21 15:43:02
 * @FilePath: \产品网站3.0\src\router\modules\SinglePage.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Layout from "@/layout/index.vue"

export const SinglePageRoute = [
  {
    path: "/trynow",
    name: "trynow",
    component: () => import("../../views/trynow/index.vue"),
    meta: {
      title: "立即试用",
      keywords: '传信下载,下载传信客户端',
      description: '传信:领雁科技打造的一个企业级工作沟通,系统办公的软件,实现数字化办公'
    },
  },
  {
    path: "/ecologycooperation",
    name: "ecologycooperation",
    component: () => import("../../views/ecologycooperation/index.vue"),
    meta: {
      title: "生态合作",
    },
  },
  {
    path: "/companyprofile",
    name: "companyprofile",
    component: () => import("../../views/companyprofile/index.vue"),
    meta: {
      title: "公司简介",
      keywords: '北京领雁科技有限公司,客户深度经营的推动者',
      description: '关于领雁:北京领雁科技股份有限公司定位于做客户深度经营的推动者，致力于为金融机构提供领先的产品和专业化服务。公司成立于2001年，总部位于北京，在上海、广州、成都、郑州、武汉等地设有5大软件中心，现有员工1800余人。'
      
    },
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../../views/news/index.vue"),
    meta: {
      title: "新闻资讯",
    },
  },
];
