/*
 * @Author: zhangyuansheng zhangyuansheng@elinkmart.com
 * @Date: 2025-02-20 15:13:00
 * @LastEditors: zhangyuansheng zhangyuansheng@elinkmart.com
 * @LastEditTime: 2025-02-21 16:24:54
 * @FilePath: \产品网站3.0\src\router\index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory,createWebHashHistory, RouteRecordRaw } from "vue-router"
import { ProductIntroductionRoute } from "./modules/ProductIntroduction"
import { SinglePageRoute } from "./modules/SinglePage"
import { PriceRoute } from "./modules/price"
import Home  from "@/views/home/index.vue"
const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: "/home",
    name:'home',
    component: () => import('../views/home/index.vue'),
    meta: {
      title: "CherryMe移动办公",
      keywords: '北京领雁科技,移动金融,即时通讯,团队协作,简化流程,移动办公,效率提升软件,移动展业,移动营销,厅堂展业,移动开发平台,开发组件,开发环境,跨平台开发框架,虚拟化技术,自动化构建工具,快速迭代,部署,提高开发效率,移动审批,OA系统,移动OA,考勤软件,考勤系统,知识管理,考勤管理,人事管理系统',
      description: '将日常办公管理信息化、线上化、数字化，以人为中心串联数字化核心应用场景;安全底座、服务底座、数据底座，助力用户构建一站式协作管理数字化平台'
    },
  },
  {
    path: "/",
    component: () => import('../views/home/index.vue'),
    redirect: "/home",
    children:[]
  },
  {
    path: '/:pathMatch(.*)', //所有未匹配的路由重定向到home页面
    redirect: '/'
  }
  
]

export const routes = [
  
  // ...redirectPages,
  // ...asyncRoutes,
  //  ...externalLinkRoutes,
   ...ProductIntroductionRoute,
  //  ...errorPageRoutes,
   ...SinglePageRoute,
   ...PriceRoute,
   ...constantRoutes,
]
const routersdata = [
  ...PriceRoute,
  ...ProductIntroductionRoute,
  ...SinglePageRoute,
  {
    path: "/home",
    name:'home',
    component: () => import('../views/home/index.vue'),
    meta: {
      title: "CherryMe移动办公",
      keywords: '北京领雁科技,移动金融,即时通讯,团队协作,简化流程,移动办公,效率提升软件,移动展业,移动营销,厅堂展业,移动开发平台,开发组件,开发环境,跨平台开发框架,虚拟化技术,自动化构建工具,快速迭代,部署,提高开发效率,移动审批,OA系统,移动OA,考勤软件,考勤系统,知识管理,考勤管理,人事管理系统',
      description: '将日常办公管理信息化、线上化、数字化，以人为中心串联数字化核心应用场景;安全底座、服务底座、数据底座，助力用户构建一站式协作管理数字化平台'
    },
  },
]
routersdata.forEach((to) => {
  console.log('路由======',to.meta);
  console.log('title======',to.meta.title);
  const {title, keywords, description} = to.meta
  if (title) {
    console.log('title111=====',title);
    document.title = title
  }
  if (keywords) {
    console.log('keywords222=====',keywords);
    const metaKeywords = document.querySelector('meta[name="keywords"]')
    if (metaKeywords) {
      console.log('metaKeywords333=====',metaKeywords);
      metaKeywords.content = keywords
    }
  }
  if (description) {
    console.log('description444=====',description);
    const metaDescription = document.querySelector('meta[name="description"]')
    if (metaDescription) {
    console.log('metaDescription555=====',metaDescription);
    metaDescription.content = description
    }
  }
})
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
router.afterEach(() => {
  // 页面滚动复位
  window.scrollTo(0, 0);
});

export default router

