/*
 * @Author: zhangyuansheng zhangyuansheng@elinkmart.com
 * @Date: 2025-02-20 15:13:00
 * @LastEditors: zhangyuansheng zhangyuansheng@elinkmart.com
 * @LastEditTime: 2025-02-21 14:16:33
 * @FilePath: \产品网站3.0\src\router\modules\price.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const PriceRoute = [
    {
      path: "/CherryMe",
      name: "CherryMe",
      component: () => import("../../views/price/Channel/index.vue"),
      meta: {
        title: "CherryMe移动办公",
        keywords: '登录认证, 即时通讯,办公协同,意见与建议,公众号',
        description: 'AI时代提供智能、惠普、开放、简单的产品能力'
      },
    },
    {
      path: "/CherryMPS",
      name: "CherryMPS",
      component: () => import("../../views/price/CherryMPS/index.vue"),
      meta: {
        title: "CherryMPS移动开发平台",
        keywords: '登录认证, APP相关,小程序相关,系统管理,运维中心',
        description: '安全、强大、普惠的数字化生产力平台'
      },
    },
    {
      path: "/laws",
      name: "laws",
      component: () => import("../../views/price/Laws/index.vue"),
      meta: {
        title: "法律声明", 
      },
    },
    {
      path: "/use",
      name: "use",
      component: () => import("../../views/price/Use/index.vue"),
      meta: {
        title: "使用声明",
      },
    },
    {
      path: "/promise",
      name: "promise",
      component: () => import("../../views/price/Promise/index.vue"),
      meta: {
        title: "合法用途承诺",
      },
    },
    
  ];
  